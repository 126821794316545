<template>
  <div class="editPrice">
    <spec-list
      :listClassModels="listClassModels"
      :productModelPrice="productModelPrice"
      @changeSpec="changeSpec"
    ></spec-list>
    <product-info
      @editing="editing"
      :detailData="detailData"
      :productModelPrice="productModelPrice"
      :currentPrice="currentPrice"
      :listClassModels="listClassModels"
      @changeCurrentPrice="changeCurrentPrice"
    ></product-info>
    <div v-show="editingShow" class="editingShow">
      <bulk-editing
        @editing="editing"
        @changeProfit="changeProfit"
        @changeScaleProfit="changeScaleProfit"
      ></bulk-editing>
    </div>
    <div class="btWrapper" @click="sub">
      <div :class="detailData.IsCouponProduct ? 'disBox' : 'btBox'">
        {{ $t.done }}
      </div>
    </div>
  </div>
</template>
<script>
import SpecList from "./components/SpecList";
import ProductInfo from "./components/ProductInfo";
import BulkEditing from "./components/BulkEditing";
import Vue from "vue";
import { Popup } from "vant";
Vue.use(Popup);
export default {
  name: "EditPrice",
  components: {
    SpecList,
    ProductInfo,
    BulkEditing,
  },
  data() {
    return {
      editingShow: false,
      productNumber: "",
      shopId: 0, //店
      detailData: {}, //详情对象
      currentPrice: "", //当前价格
      listClassModels: [], //规格列表
      listViewProductModelPriceOrIMG: [], //规格组合列表
      productModelPrice: {}, //当前使用规格对象
    };
  },
  beforeMount() {
    const { ProductNumber, ShopId } = this.$route.query;
    this.productNumber = ProductNumber;
    this.shopId = ShopId;
  },
  mounted() {
    this.getProductInfoByCustomer();
  },
  methods: {
    editing() {
      this.editingShow = !this.editingShow;
    },
    // 获取商品详情
    getProductInfoByCustomer() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        productNumber: this.productNumber,
      };
      this.$api.product
        .loadB_ProductInfoByShop(param)
        .then((res) => {
          const data = res.data;
          this.detailData = data;
          this.listClassModels = data.ListClassModels;
          this.listViewProductModelPriceOrIMG =
            data.ListViewProductModelPriceOrIMG;
          //获取当前使用规格
          if (
            this.listClassModels.length &&
            this.listViewProductModelPriceOrIMG.length
          ) {
            this.getProductModelPrice();
            this.currentPrice = this.productModelPrice.CurrentPrice;
          }
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
          });
        });
    },
    // 初始化获取当前使用规格
    getProductModelPrice() {
      if (this.listViewProductModelPriceOrIMG.length == 0) {
        this.productModelPrice = this.listViewProductModelPriceOrIMG[0];
      } else {
        var SonClassIds = [];
        for (const parent of this.listClassModels) {
          SonClassIds.push(parent.SonClassModels[0].PCModelId);
        }
        //console.log(SonClassIds);
        var spliterSon = SonClassIds.join("|");
        //console.log(spliterSon);
        for (const product of this.listViewProductModelPriceOrIMG) {
          var spliter = product.PCModelId.split("|");
          var firstNum = 0;
          if (parseInt(spliter[0])) {
            firstNum = parseInt(spliter[0]);
          }
          //console.log(firstNum+',firstNum');
          if (!SonClassIds[1]) {
            //单个参数
            if (firstNum == SonClassIds[0]) {
              this.productModelPrice = product;
              break;
            }
          } else {
            //多个参数
            if (spliterSon == product.PCModelId) {
              this.productModelPrice = product;
              break;
            }
          }
        }
        //console.log(this.productModelPrice)
      }
    },
    //选择规格item为规格对象,pCModelId为规格id，index所属规格下标
    changeSpec(item, pCModelId, index) {
      let productSpringList = this.productModelPrice.PCModelId.split("|");
      productSpringList.splice(index, 1, pCModelId);
      //当前使用组合规格
      this.productModelPrice = this.listViewProductModelPriceOrIMG.filter(
        (newItem) => {
          if (newItem.PCModelId === productSpringList.join("|")) {
            return newItem;
          }
        }
      )[0];
      this.currentPrice = this.productModelPrice.CurrentPrice;
    },
    // 修改售价
    changeCurrentPrice(price) {
      this.currentPrice = price;
      this.productModelPrice.CurrentPrice = price;
      this.listViewProductModelPriceOrIMG.forEach((item, index) => {
        if (item.PCModelId == this.productModelPrice.PCModelId) {
          this.listViewProductModelPriceOrIMG.fill(
            this.productModelPrice,
            index,
            index
          );
        }
      });
      //console.log(this.productModelPrice);
      //console.log(this.listViewProductModelPriceOrIMG);
    },
    // 批量修改利润比例
    changeScaleProfit(scaleProfit) {
      // console.log(scaleProfit);
      // console.log(scaleProfit);
      let newListViewProductModelPriceOrIMG = [];
      this.listViewProductModelPriceOrIMG.forEach((item) => {
        let newItem = item;
        newItem.CurrentPrice = this.accMul(
          this.accAddInt(scaleProfit, 100) / 100,
          newItem.CustomerCurrentPrice
        );
        newListViewProductModelPriceOrIMG.push(newItem);
      });
      //新规格组合
      this.listViewProductModelPriceOrIMG = newListViewProductModelPriceOrIMG;
      //console.log(newListViewProductModelPriceOrIMG);
      //当前使用组合规格
      this.productModelPrice = newListViewProductModelPriceOrIMG.filter(
        (item) => {
          if (item.PCModelId === this.productModelPrice.PCModelId) return item;
        }
      )[0];
      // 设置当前售价
      this.currentPrice = this.productModelPrice.CurrentPrice;
    },
    //批量修改利润
    changeProfit(profit) {
      //console.log(profit);
      let newListViewProductModelPriceOrIMG = [];
      this.listViewProductModelPriceOrIMG.forEach((item) => {
        let newItem = item;
        newItem.CurrentPrice = this.accAddInt(
          profit,
          newItem.CustomerCurrentPrice
        );
        newListViewProductModelPriceOrIMG.push(newItem);
      });
      //新规格组合
      this.listViewProductModelPriceOrIMG = newListViewProductModelPriceOrIMG;
      //console.log(newListViewProductModelPriceOrIMG);
      //当前使用组合规格
      this.productModelPrice = newListViewProductModelPriceOrIMG.filter(
        (item) => {
          if (item.PCModelId === this.productModelPrice.PCModelId) return item;
        }
      )[0];
      // 设置当前售价
      this.currentPrice = this.productModelPrice.CurrentPrice;
    },
    //加法函数
    accAddInt(arg1, arg2) {
      let r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));

      return (arg1 * m + arg2 * m) / m;
    },
    //乘法函数
    accMul(arg1, arg2) {
      let m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {
        console.log(e);
      }
      try {
        m += s2.split(".")[1].length;
      } catch (e) {
        console.log(e);
      }
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      ).toFixed(2);
    },
    // 精确减法
    subtr(arg1, arg2) {
      let r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      //last modify by deeka
      //动态控制精度长度
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    //提交
    editProductModelPrice(param) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      this.$api.product
        .editProductModelPrice(param)
        .then((res) => {
          const data = res.data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonMethod.dialogAlert(res.message, () => {
            this.getProductInfoByCustomer();
          });
        })
        .catch((error) => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    //保存方法
    sub() {
      if (this.detailData.IsCouponProduct) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: this.$t.couponInterpret.editPriceTxt1,
        });
        return;
      }
      let productModelPrice = this.productModelPrice;
      let reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      let profit = this.subtr(
        this.currentPrice,
        productModelPrice.CustomerCurrentPrice
      );
      // 判断利润是否为负数
      if (!reg.test(profit)) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
          msg: this.$t.couponInterpret.editPriceTxt2,
        });
        return;
      }
      // 设置当前组合
      this.productModelPrice.CurrentPrice = parseFloat(this.currentPrice);
      this.listViewProductModelPriceOrIMG.forEach((item, index) => {
        if (item.PCModelId == this.productModelPrice.PCModelId) {
          this.listViewProductModelPriceOrIMG.fill(
            this.productModelPrice,
            index,
            index
          );
        }
      });
      let param = {
        ProductNumber: this.productNumber,
        ShopId: this.shopId,
        ProductModelPriceList: [],
      };
      this.listViewProductModelPriceOrIMG.forEach((item) => {
        let model = {};
        model.PCModelId = item.PCModelId;
        model.CurrentPrice = item.CurrentPrice;
        param.ProductModelPriceList.push(model);
      });
      this.editProductModelPrice(param);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.editPrice {
  width: 100%;
  margin: 0 auto;
  position: relative;
  .editingShow {
    transition: all 3 ease;
    -webkit-transition: all 3 ease;
  }
  .btWrapper {
    width: $publicWidth;
    margin: 8% auto;
    .disBox {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: #999;
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
    .btBox {
      position: relative;
      width: 100%;
      margin: 0 auto;
      border-radius: 3rem;
      padding: 0.8rem 0;
      text-align: center;
      background: linear-gradient(left, #71d283, #01aaa3);
      color: #ffffff;
      font-size: 1rem;
      line-height: 1;
    }
  }
}
</style>
