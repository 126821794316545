<template>
  <div class="bulkEditingBox">
    <div class="model" @click="editing"></div>
    <div class="bulkEditing">
      <section class="titleBox">
        <div class="titleItem" @click="changeType(0)">
          <p :class="['titleName', type === 0 ? 'titleNameAction' : '']">
            {{ $t.profitAmount }}
          </p>
          <p
            :class="['titleBottom', type === 0 ? 'titleBottomAction' : '']"
          ></p>
        </div>
        <div class="titleItem" @click="changeType(1)">
          <p :class="['titleName', type === 1 ? 'titleNameAction' : '']">
            {{ $t.profitScale }}
          </p>
          <p
            :class="['titleBottom', type === 1 ? 'titleBottomAction' : '']"
          ></p>
        </div>
      </section>
      <section class="sellingPriceBox" v-show="type === 0">
        <p class="sellingPriceUnit">$</p>
        <input class="sellingPriceInput" v-model="profit" />
      </section>
      <section class="sellingPriceBox" v-show="type === 1">
        <input class="sellingPriceInput" v-model="scaleProfit" />
        <p class="sellingPriceUnit">%</p>
      </section>
      <div class="btBox">
        <div class="cancel" @click="editing">{{ $t.cancel }}</div>
        <div class="affirm" @click="sub">{{ $t.confirm }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BulkEditing",
  data() {
    return {
      type: 0,
      profit: 0, //利润
      scaleProfit: 0, //利润比例
    };
  },
  methods: {
    editing() {
      this.$emit("editing");
    },
    changeType(type) {
      this.type = type;
    },
    sub() {
      let reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
      if (this.type === 0) {
        if (!reg.test(this.profit)) {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: "利润必须为非负数",
          });
          return;
        }
        this.$emit("changeProfit", this.profit);
      } else {
        if (!reg.test(this.scaleProfit)) {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: "利润比例必须为非负数",
          });
          return;
        }
        this.$emit("changeScaleProfit", this.scaleProfit);
      }
      this.editing();
    },
    // //批量修改利润
    // changeProfit() {
    //   this.$emit("changeProfit", this.profit);
    //   this.editing();
    // },
    // //批量修改利润比例
    // changeScaleProfit() {
    //   this.$emit("changeScaleProfit", this.scaleProfit);
    //   this.editing();
    // }
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.bulkEditingBox {
  .model {
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: #000000;
    opacity: 0.6;
    z-index: 10;
  }
  .bulkEditing {
    z-index: 10;
    width: 92%;
    padding: 20px;
    @include publicBoxRadio;
    box-sizing: border-box;
    background-color: #ffffff;
    // color: red;
    transform: translate(-50%, -50%);
    position: fixed;
    left: 50%;
    top: 50%;
    .titleBox {
      padding: 20px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      @include publicFlex;
      justify-content: space-around;
      -webkit-justify-content: space-around;
      .titleItem {
        text-align: center;
        width: 50%;
        .titleName {
          margin: 0;
          padding: 0;
          text-align: center;
          font-size: 16px;
          color: #a7a7a7;
        }
        .titleNameAction {
          color: #43cd9a;
          font-size: 16px;
          font-weight: bold;
        }
        .titleBottom {
          width: 30px;
          height: 4px;
          border-radius: 13px;
          background-color: #43cd9a;
          margin: 10px auto;
          padding: 0;
          opacity: 0;
          -webkit-opacity: 0;
        }
        .titleBottomAction {
          opacity: 1;
          -webkit-opacity: 1;
        }
      }
    }
    .sellingPriceBox {
      @include publicFlex;
      width: 75%;
      margin: 0 auto;
      border: none;
      background-color: #f6f6f6;
      padding: 10px 15px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border-radius: 45px;
      .sellingPriceUnit {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #000000;
        width: 10%;
      }
      .sellingPriceInput {
        margin: 0;
        padding: 0;
        border: none;
        background-color: #f6f6f6;
        width: 90%;
      }
    }
    .btBox {
      @include publicFlex;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      width: 100%;
      padding-top: 40px;
      .cancel {
        width: 47%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background-color: #f6f6f6;
        color: #000000;
        font-size: 14px;
        border-radius: 50px;
      }
      .affirm {
        width: 47%;
        text-align: center;
        height: 40px;
        line-height: 40px;
        background-color: #43cd9a;
        color: #ffffff;
        font-size: 14px;
        border-radius: 50px;
      }
    }
  }
}
</style>
